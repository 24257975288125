<template>
    <v-row class="pa-0 ma-0 top-base">
        <v-col cols="12" class="text-center">
            <div class="top-title" ></div>
            <button @click="util$.tolink('/tutorial')" class="top-start-btn"></button>
        </v-col>
    </v-row>
</template>

<script>
// トップページ
import util$ from '@/util'

export default {
    name: 'Top',
    data: function () {
        return {
            util$: util$
        }
    }
}
</script>
