<template>
    <v-row class="pa-0 ma-0 content-body">
        <v-col v-if="guideShow" cols="12" class="ma-0 pa-0 guide-area">
            <v-row class="ma-0 pa-0">
                <v-col cols="12" class="guide-top-content text-center pa-6">
                    <img class="guide-title" :src="'/static/image/guide/playguide.png'">
                </v-col>
            </v-row>
            <v-row class="mt-0 ml-0 mr-0 pa-0 guide-middle">
                <v-col cols="12" class="guide-middle-content" v-if="spot === 'sdgs_in'">
                    <p><img src="/static/image/guide/sdgs_in_01.png" class="guide-text"></p>
                    <p><img src="/static/image/guide/sdgs_map.png" class="guide-map"></p>
                    <p><img src="/static/image/guide/sdgs_in_02.png" class="guide-text"></p>
                    <p><img src="/static/image/guide/sdgs_in_03.png" class="guide-text"></p>
                    <p><img src="/static/image/guide/sdgs_marker.png" class="guide-marker"></p>
                    <p><img src="/static/image/guide/sdgs_in_04.png" class="guide-text"></p>
                </v-col>
                <v-col cols="12" class="guide-middle-content" v-if="spot === 'sdgs_out'">
                    <p><img src="/static/image/guide/sdgs_out_00.png" class="guide-text"></p>
                    <p><img src="/static/image/guide/workation_map.png" class="guide-map"></p>
                </v-col>
                <v-col cols="12" class="guide-middle-content" v-if="spot === 'volcano_in'">
                    <p><img src="/static/image/guide/volcano_in_01.png" class="guide-text"></p>
                    <p><img src="/static/image/guide/volcano_map.png" class="guide-map"></p>
                    <p><img src="/static/image/guide/volcano_in_02.png" class="guide-text"></p>
                    <p><img src="/static/image/guide/volcano_in_03.png" class="guide-text"></p>
                    <p><img src="/static/image/guide/volcano_marker.png" class="guide-marker"></p>
                    <p><img src="/static/image/guide/volcano_in_04.png" class="guide-text"></p>
                </v-col>
                <v-col cols="12" class="guide-middle-content" v-if="spot === 'volcano_out'">
                    <p><img src="/static/image/guide/volcano_out_00.png" class="guide-text"></p>
                    <p><img src="/static/image/guide/workation_map.png" class="guide-map"></p>
                </v-col>
            </v-row>
            <v-row ref="guideBottom" class="ma-0 pa-0 guide-bottom">
                <v-col cols="12" class="guide-bottom-content">
                    <button @click="util$.tolink('/select')" class="guide-back-btn"></button>
                    <img class="guide-camera" :src="'/static/image/guide/cam_on.png'" @click="move">
                </v-col>
            </v-row>
        </v-col>
        <v-col v-if="inShow" cols="12" :class="[wideFlg ? 'inside-wide' : 'inside-vert']">
            <div class="camera-area">
                <iframe :src="'/static/ar/' + spot + '.html'" class="video-iframe"></iframe>
                <button @click="back" class="cam-back-btn"></button>
                <button @click="next" class="cam-skip-btn"></button>
                <img id="ar_effect_img" ref="arEffectImg" :src="[wideFlg ? '/static/image/ar/fade-in-asoka.png' : '/static/image/ar/v_fade-in-asoka.png']" class="ar-effect-img">
            </div>
        </v-col>
        <v-col id="videoArea" ref="videoArea" cols="12" :class="[wideFlg ? 'inside-wide' : 'inside-vert']" style="display: none;">
            <div class="inside-video-area">
                <audio id="playSound" ref="sound1" preload="auto" style="display:none" :src="[spot === 'volcano_in' ? '/static/sound/ar_sound_rabbit.mp3' : '/static/sound/ar_sound_mouse.mp3' ]"></audio>
                <video id="playVideo" ref="video1" class="inside-video" preload="auto" :style="mvStyle" :src="'/static/movie/' + spot + '.mp4'" playsinline v-on:ended="next"></video>
                <button @click="back" class="cam-back-btn"></button>
                <button v-if="aaa" @click="next" class="cam-next-btn"></button>
            </div>
        </v-col>
    </v-row>
</template>

<script>
// トップページ
import util$ from '@/util'
import { GlEvBus } from '@/GlobalEventBus'

export default {
    name: 'GuideInOut',
    data: function () {
        return {
            wideFlg: false,
            util$: util$,
            spot: this.$route.params.spot,
            guideShow: true,
            inShow: false,
            outShow: false,
            aaa: true,
            mvStyle: {
                /*
                display: 'none',
                width: '100%',
                height: '100vh',
                top: '0px',
                bottom: '0px',
                position: 'absolute'
                */
            }
        }
    },
    mounted: function () {
        let self$ = this
        GlEvBus.$off('PhoneV')
        GlEvBus.$on('PhoneV', function () {
            self$.aaa = false
            self$.wideFlg = false
            setTimeout(function () { self$.setMvStyle(self$) }, 500)
        })
        GlEvBus.$off('PhoneW')
        GlEvBus.$on('PhoneW', function () {
            self$.aaa = false
            self$.wideFlg = true
            setTimeout(function () { self$.setMvStyle(self$) }, 500)
        })
        let winW = window.innerWidth
        let winH = window.innerHeight
        this.wideFlg = (winW > winH) ? true : false
    },
    methods: {
        move: function () {
            if (this.spot === 'volcano_in' || this.spot === 'sdgs_in') {
                this.$refs.video1.play()
                this.$refs.video1.pause()
                this.$refs.sound1.play()
                this.$refs.sound1.pause()
                this.inShow = true
                this.guideShow = false
                // this.mvStyle.display = 'none'
                this.setMvStyle(this)
                setTimeout(function () {
                    let vh = window.innerHeight * 0.01;
                    document.documentElement.style.setProperty('--vh', `${vh}px`)
                }, 400)
            } else {
                util$.tolink('/outside/' + this.spot)
            }
        },
        back: function () {
            this.$refs.video1.pause()
            this.$refs.video1.currentTime = 0
            this.$refs.sound1.pause()
            this.$refs.sound1.currentTime = 0
            this.inShow = false
            this.guideShow = true
            this.$refs.videoArea.style.display = 'none'
            location.reload()
        },
        next: function () {
            this.inShow = false
            util$.tolink('/end/' + this.spot)
        },
        setMvStyle: function (self$) {
            let winW = window.innerWidth
            let winH = window.innerHeight
            if (winH > winW) {
                self$.mvStyle.width = winH + 'px'
                self$.mvStyle.height = winW + 'px'
                self$.mvStyle.left = '-' + ((winH - winW) / 2) + 'px'
            } else {
                self$.mvStyle.width = winW + 'px'
                self$.mvStyle.height = winH + 'px'
                self$.mvStyle.left = '0px'
            }
            self$.aaa = true
        }
    }
}
</script>
