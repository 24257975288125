<template>
    <v-row :id="'end_' + spot" :class="[wideFlg ? 'end-wide' : 'end-vert']">
        <v-col cols="12" class="pa-0 ma-0 end-area">
            <div v-if="spot === 'sdgs_in' || spot === 'sdgs_out'" class="end-btn-area end-sdgs-area">
                <img :src="[wideFlg ? '/static/image/end/sdgs_sub_1.png' : '/static/image/end/v_sdgs_sub_1.png']" class="end-sub-btn" @click="openLink('https://youtu.be/mWWbY3O0Lr0')">
                <img :src="[wideFlg ? '/static/image/end/sdgs_sub_2.png' : '/static/image/end/v_sdgs_sub_2.png']" class="end-sub-btn" @click="openLink('https://youtu.be/g3ansSs3Rxo')">
            </div>
            <div v-if="spot === 'volcano_in' || spot === 'volcano_out'" class="end-btn-area end-volcano-area">
                <div class="volcano-endbtn-area">
                    <img :src="[wideFlg ? '/static/image/end/volcano_sub_1.png' : '/static/image/end/v_volcano_sub_1.png']" class="end-sub-btn end-volcano-btn1" @click="openLink('https://youtu.be/cGikKTHSsnA')">
                    <img :src="[wideFlg ? '/static/image/end/volcano_sub_4.png' : '/static/image/end/v_volcano_sub_4.png']" class="end-sub-btn end-volcano-btn2" @click="openLink('https://youtu.be/Zyeqv_-PucI')">
                    <img :src="[wideFlg ? '/static/image/end/volcano_sub_3.png' : '/static/image/end/v_volcano_sub_3.png']" class="end-sub-btn end-volcano-btn3" @click="openLink('https://youtu.be/9tsNXYcZufQ')">
                    <img :src="[wideFlg ? '/static/image/end/volcano_sub_5.png' : '/static/image/end/v_volcano_sub_5.png']" class="end-sub-btn end-volcano-btn4" @click="openLink('https://youtu.be/9yYmXKYK360')">
                    <img :src="[wideFlg ? '/static/image/end/volcano_sub_2.png' : '/static/image/end/v_volcano_sub_2.png']" class="end-sub-btn end-volcano-btn5" @click="openLink('https://youtu.be/cIVpndIAyBI')">
                </div>
            </div>
            <button @click="end" class="end-end-btn"></button>
        </v-col>
    </v-row>
</template>

<script>
import util$ from '@/util'
import { GlEvBus } from '@/GlobalEventBus'

export default {
    name: 'End',
    data: function () {
        return {
            util$: util$,
            spot: this.$route.params.spot,
            wideFlg: false
        }
    },
    mounted: function () {
        let self$ = this
        GlEvBus.$off('PhoneV')
        GlEvBus.$on('PhoneV', function () {
            self$.wideFlg = false
        })
        GlEvBus.$off('PhoneW')
        GlEvBus.$on('PhoneW', function () {
            self$.wideFlg = true
        })
        let bW = document.body.clientWidth
        let bH = document.body.clientHeight
        this.wideFlg = (bW > bH) ? true : false
    },
    methods: {
        end: function () {
            util$.tolink('/select')
        },
        openLink: function (url) {
            window.open(url, '_blank')
        }
    }
}
</script>
