<template>
    <v-row :class="[wideFlg ? 'outside-wide' : 'outside-vert']">
        <v-col v-show="camShow" cols="12" class="pa-0 ma-0 camera-area">
            <video ref="camera" class="camera-view" muted playsinline style="object-fit: cover;"></video>
            <img v-if="spot === 'sdgs_out' && !wideFlg" src="/static/image/camera/v_search_icon.png" class="outside-search">
            <img v-if="spot === 'volcano_out' && !wideFlg" src="/static/image/camera/v_mt_outline.png" class="outside-outline">
            <img v-if="spot === 'sdgs_out' && wideFlg" src="/static/image/camera/search_icon.png" class="outside-search">
            <img v-if="spot === 'volcano_out' && wideFlg" src="/static/image/camera/mt_outline.png" class="outside-outline">
            <div v-if="spot === 'sdgs_out'" class="sdgs-telop"></div>
            <div v-if="spot === 'volcano_out'" class="volcano-telop"></div>
            <button @click="back" class="cam-back-btn"></button>
            <button @click="search" class="outside-gps-btn"></button>
            <img ref="gpsEffectImg" :src="[wideFlg ? '/static/image/ar/fade-in-asoka.png' : '/static/image/ar/v_fade-in-asoka.png']" class="gps-effect-img">
        </v-col>
         <v-col v-show="videoShow" cols="12" class="ma-0 pa-0" style="position: absolute; top: 0; left: 0;">
            <div class="outside-video-area">
                <video ref="video2" class="outside-video" preload="auto" :style="mvStyle" :src="'/static/movie/' + spot + '.mp4'" playsinline v-on:ended="next"></video>
                <button @click="back" class="cam-back-btn"></button>
                <button v-if="aaa" @click="next" class="cam-next-btn"></button>
            </div>
        </v-col>
   </v-row>
</template>

<script>
import util$ from '@/util'
import { GlEvBus } from '@/GlobalEventBus'
import { ReqClass } from '@/class/Request.class'

export default {
    name: 'Outside',
    data: function () {
        return {
            wideFlg: false,
            util$: util$,
            effectTime: 3000,
            dispLocationUrl: null,
            spot: this.$route.params.spot,
            cameraOption: { audio: false, video: { facingMode: 'environment' } }, /* カメラのサイズを画面サイズと同様にすくれば黒背景はなくなる模様 */
            gps: {longitude: null, latitude: null, distance: null},
            stream: null,
            wStream: null,
            camShow: true,
            videoShow: false,
            aaa: true,
            mvStyle: {
                /*
                width: '100%',
                height: '100vh',
                top: '0px',
                bottom: '0px',
                position: 'absolute'
                */
            }
        }
    },
    mounted: function () {
        let self$ = this
        GlEvBus.$off('PhoneV')
        GlEvBus.$on('PhoneV', function () {
            self$.wideFlg = false
            if (!self$.videoShow) {
                self$.cameraOff(self$)
                self$.cameraPlay()
            } else {
                self$.aaa = false
                self$.setMvStyle(self$)
            }
        })
        GlEvBus.$off('PhoneW')
        GlEvBus.$on('PhoneW', function () {
            self$.wideFlg = true
            if (!self$.videoShow) {
                self$.cameraOff(self$)
                self$.cameraPlay()
            } else {
                self$.aaa = false
                self$.setMvStyle(self$)
            }
        })
        this.init()
    },
    beforeDestroy: function () {
        this.cameraOff(this)
    },
    methods: {
        async init () {
            this.camShow = true
            this.videoShow = false
            await this.getGpsReq(this)
            this.dispLocationUrl = location.href
            let winW = window.innerWidth
            let winH = window.innerHeight
            this.wideFlg = (winW > winH) ? true : false
            this.cameraPlay()
        },
        async cameraPlay () {
            this.cameraOption.video.width = 1600
            this.cameraOption.video.height = 900
            try {
                this.stream = await navigator.mediaDevices.getUserMedia(this.cameraOption)
            } catch (err) {
                console.log(err)
                util$.msgWarnOpen('カメラ使用の許可が得られませんでした。\n', function () { })
                return false
            }
            this.$refs.camera.srcObject = this.stream
            this.$refs.camera.play()
        },
        getGpsReq: function (self$) {
            return new Promise((resolve) => {
                let reqObj = new ReqClass('/static/gps.json')
                reqObj.get(function (rDat) {
                    self$.gps = rDat[self$.spot]
                    return resolve(self$)
                })
            })
        },
        cameraOff: function (self$) {
            if (typeof self$ !== 'undefined') {
                if (self$.stream && typeof self$.stream.getVideoTracks === 'function') {
                    self$.stream.getVideoTracks().forEach((track) => {
                        track.stop()
                    })
                    self$.stream = null
                }
                if (self$.wStream && typeof self$.wStream.getVideoTracks === 'function') {
                    self$.wStream.getVideoTracks().forEach((track) => {
                        track.stop()
                    })
                    self$.wStream = null
                }
            }
        },
        back: function () {
            this.cameraOff(this)
            util$.tolink('/guide/' + this.spot)
        },
        next: function () {
            this.cameraOff(this)
            util$.tolink('/end/' + this.spot)
        },
        async search () {
            try {
                await this.getCurrentPos(this)
            } catch (err) {
                util$.msgWarnOpen(err)
            }
            this.checkGps()
        },
        getCurrentPos: function (self$) {
            return new Promise((resolve, reject) => {
                const message = [
                    '現在位置が取得できませんでした',
                    '位置情報の利用が許可されていません。設定より位置情報取得の許可を行ってください。また、ブラウザに対しても位置情報許可を設定する必要がありますのでご注意ください。',
                    '現在位置が取得できませんでした',
                    'タイムアウトになりました',
                    '現在地情報を取得できませんでした。お使いのブラウザでは現在地情報を利用できない可能性があります。エリアを入力してください。'
                ]
                if (window.navigator.geolocation) {
                    window.navigator.geolocation.getCurrentPosition(
                        response => {
                            self$.cPosition = response.coords
                            resolve(response)
                        },
                        error => {
                            reject(message[error.code])
                        },
                        {
                            enableHighAccuracy: false,
                            timeout: 5000,
                            maximumAge: 0
                        }
                    )
                } else {
                    reject(message[4])
                }
            })
        },
        checkGps: function () {
            let distance = this.getDistance() * 1000 // 座標間の距離(m)
            if (this.gps.distance > distance) {
                let self$ = this
                this.$refs.gpsEffectImg.style.display = 'block'
                setTimeout(function () { self$.movieStart(self$) }, this.effectTime)
            } else {
                util$.msgInfoOpen("GPSエリア外です。\nワーケーションエリアに移動してから\n「GPSサーチ」を押してください。")
            }
        },
        movieStart: function (self$) {
            if (self$.dispLocationUrl === location.href && self$.camShow) {
                self$.camShow = false
                self$.videoShow = true
                self$.$refs.video2.play()
                self$.setMvStyle(self$)
                self$.cameraOff(self$)
            }
        },
        getDistance: function () {
            const earth = 6371
            let lat1 = this.gps.latitude * Math.PI / 180
            let lng1 = this.gps.longitude * Math.PI / 180
            let lat2 = this.cPosition.latitude * Math.PI / 180
            let lng2 = this.cPosition.longitude * Math.PI / 180
            return earth * Math.acos(Math.cos(lat1) * Math.cos(lat2) * Math.cos(lng2 - lng1) + Math.sin(lat1) * Math.sin(lat2))
        },
        setMvStyle: function (self$) {
            let winW = window.innerWidth
            let winH = window.innerHeight
            if (winH > winW) {
                self$.mvStyle.width = winH + 'px'
                self$.mvStyle.height = winW + 'px'
                self$.mvStyle.left = '-' + ((winH - winW) / 2) + 'px'
            } else {
                self$.mvStyle.width = winW + 'px'
                self$.mvStyle.height = winH + 'px'
                self$.mvStyle.left = '0px'
            }
            self$.aaa = true
        }
    }
}
</script>
