<template>
    <v-dialog v-model="msgDialog" persistent width="100%" max-width="600px">
            <v-row class="modal_title ma-0">
                <v-col cols="9" class="pb-2 pt-2">
                    <span>{{msg.title}}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeClick" dark>
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </v-row>
            <v-row class="msg-dialog-body ma-0">
                <v-col cols="12">
                    {{msg.body}}
                </v-col>
            </v-row>
            <v-row class="modal_footer ma-0">
                <v-col cols="12" class="text-right pt-1 pb-1 mb-1 mt-1">
                    <v-btn v-if="vMsgCancelBtn" @click="cancelClick">キャンセル</v-btn>
                    <v-btn v-if="vMsgOkBtn" class="ml-2" @click="okClick" color="grey lighten-1">了解</v-btn>
                    <v-btn v-if="vMsgCloseBtn" class="ml-2" @click="closeClick">閉じる</v-btn>
                </v-col>
            </v-row>
    </v-dialog>
</template>

<script>
export default {
    name: 'MsgModal',
    data: function () {
        return {
            msgDialog: false,
            vMsgOkBtn: false,
            vMsgCancelBtn: false,
            vMsgCloseBtn: false,
            msg: {title: '', body: '', okFunc: null, clFunc: null, ccFunc: null}
        }
    },
    created: function () {
    },
    methods: {
        open: function (msgType, message, btn1Callback, btn2Callback) {
            this.msgInit()
            this.msg.body = message
            switch (true) {
            case msgType === 'confirm' :
                this.msg.title = '確認メッセージ'
                this.vMsgOkBtn = true
                this.vMsgCancelBtn = true
                if (typeof btn1Callback === 'function') this.msg.okFunc = btn1Callback
                if (typeof btn2Callback === 'function') this.msg.ccFunc = btn2Callback
                break
            case msgType === 'delete' :
                this.msg.title = '削除'
                this.vMsgOkBtn = true
                this.vMsgCancelBtn = true
                this.okBtnClass = 'btn bg-orange'
                if (typeof btn1Callback === 'function') this.msg.okFunc = btn1Callback
                if (typeof btn2Callback === 'function') this.msg.ccFunc = btn2Callback
                break
            case msgType === 'info' :
                this.msg.title = '確認メッセージ'
                this.vMsgOkBtn = true
                if (typeof btn1Callback === 'function') this.msg.okFunc = btn1Callback
                break
            case msgType === 'alert' :
                this.msg.title = '確認メッセージ'
                this.vMsgOkBtn = true
                if (typeof btn1Callback === 'function') this.msg.okFunc = btn1Callback
                break
            case msgType === 'warning' :
                this.msg.title = '確認メッセージ'
                this.vMsgOkBtn = true
                if (typeof btn1Callback === 'function') this.msg.okFunc = btn1Callback
                break
            case msgType === 'error' :
                this.msg.title = 'Error'
                this.vMsgOkBtn = true
                if (typeof btn1Callback === 'function') this.msg.okFunc = btn1Callback
                break
            }
            this.msgDialog = true
        },
        okClick: function () {
            if (typeof this.msg.okFunc === 'function') this.msg.okFunc()
            this.closeMsgModal()
        },
        cancelClick: function () {
            if (typeof this.msg.clFunc === 'function') this.msg.clFunc()
            this.closeMsgModal()
        },
        closeClick: function () {
            if (typeof this.msg.ccFunc === 'function') this.msg.ccFunc()
            this.closeMsgModal()
        },
        closeMsgModal: function () {
            this.msgDialog = false
            this.msgInit()
        },
        msgInit: function () {
            this.vMsgOkBtn = false
            this.vMsgCancelBtn = false
            this.vMsgCloseBtn = false
            this.msg = {title: '', body: '', okFunc: null, clFunc: null, ccFunc: null}
        }
    }
}
</script>
