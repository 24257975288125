<template>
    <div v-show="vLoading" class="loading-modal">
        <v-progress-circular
            :size="50"
            color="accent"
            indeterminate
            ></v-progress-circular>
    </div>
</template>

<script>

export default {
    data: function () {
        return {
            vLoading: false
        }
    },
    methods: {
        showLoading: function () {
            this.vLoading = true
        },
        hideLoading: function () {
            this.vLoading = false
        }
    }
}
</script>

<style scoped>
.loading-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}
</style>
