<template>
    <v-app>
        <div class="msg-dialog">
            <loading-compo ref="loading"></loading-compo>
            <msg-compo ref="msgModal"></msg-compo>
        </div>

        <v-main>
            <v-container fluid class="pa-0 ma-0">
                <router-view></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { GlEvBus } from '@/GlobalEventBus'
import Loading from '@/components/common/Loading'
import MsgModal from '@/components/common/MsgModal'

export default {
    name: 'Base',
    components: {
        'msg-compo': MsgModal,
        'loading-compo': Loading
    },
    computed: {
        routeName: function () {
            return this.$route.name
        }
    },
    created () {
        let self$ = this
        this.$vuetify.theme.dark = true
        GlEvBus.$off('SHOW_LOADING')
        GlEvBus.$on('SHOW_LOADING', function () {
            self$.$refs.loading.showLoading()
        })
        GlEvBus.$off('HIDE_LOADING')
        GlEvBus.$on('HIDE_LOADING', function () {
            self$.$refs.loading.hideLoading()
        })
        GlEvBus.$off('MsgDialogOpen')
        GlEvBus.$on('MsgDialogOpen', function (type, msg, btn1Func, btn2Func) {
            self$.$refs.msgModal.open(type, msg, btn1Func, btn2Func)
        })
    }
}

</script>
