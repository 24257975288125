<template>
    <v-row class="pa-0 ma-0 content-body">
        <v-col cols="12" class="pa-0 ma-0">
            <v-row class="pa-4 ma-0">
                <v-col cols="12" class="pa-4 ma-0 select-volcano-area">
                    <img class="select-main-img" src="/static/image/volcano_img.png" />
                    <v-row class="mt-1 pa-0">
                        <v-col cols="6" class="text-center pa-0 ma-0">
                            <button @click="util$.tolink('/guide/volcano_in')" class="select-btn volcano-in-btn"></button>
                        </v-col>
                        <v-col cols="6" class="text-center pa-0 ma-0">
                            <button @click="util$.tolink('/guide/volcano_out')" class="select-btn volcano-out-btn"></button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="pl-4 pr-4 pt-0 mt-0 ml-0 mr-0 mb-4">
                <v-col cols="12" class="pa-4 ma-0 select-sdgs-area">
                    <img class="select-main-img" src="/static/image/sdgs_img.png" />
                    <v-row class="mt-1 pa-0">
                        <v-col cols="6" class="text-center pa-0 ma-0">
                            <button @click="util$.tolink('/guide/sdgs_in')" class="select-btn sdgs-in-btn"></button>
                        </v-col>
                        <v-col cols="6" class="text-center pa-0 ma-0">
                            <button @click="util$.tolink('/guide/sdgs_out')" class="select-btn sdgs-out-btn"></button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import util$ from '@/util'

export default {
    name: 'Select',
    data: function () {
        return {
            util$: util$
        }
    }
}
</script>
