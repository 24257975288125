import Vue from 'vue'
import Router from 'vue-router'
import Top from '@/components/Top'
import Tutorial from '@/components/Tutorial'
import Select from '@/components/Select'
import GuideInOut from '@/components/GuideInOut'
import Outside from '@/components/Outside'
import End from '@/components/End'
Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Top',
            component: Top,
            meta: {
                title: 'スタート'
            }
        },
        {
            path: '/tutorial',
            name: 'Tutorial',
            component: Tutorial,
            meta: {
                title: 'チュートリアル'
            }
        },
        {
            path: '/select',
            name: 'Select',
            component: Select,
            meta: {
                title: '選択'
            }
        },
        {
            path: '/guide/:spot',
            name: 'AR説明',
            component: GuideInOut,
            meta: {
                title: 'AR説明'
            }
        },
        {
            path: '/outside/:spot',
            name: 'Outside',
            component: Outside,
            meta: {
                title: 'Outside'
            }
        },
        {
            path: '/end/:spot',
            name: 'End',
            component: End,
            meta: {
                title: 'End'
            }
        },
        {
            path: '*',
            name: 'Top',
            component: Top,
            meta: {
                title: 'スタート'
            }
        }
    ]
})

export default router
