import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#73B7C2',
                secondary: '#3393A6',
                accent: '#496A80',
                info: '#CDCFC8',
                success: '#D8814E',
                danger: '#EF5350'
            }
        }
    }
})
