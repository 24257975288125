import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from '@/plugins/vuetify'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ja from 'vee-validate/dist/locale/ja.json'
import '@/assets/css/app.css'
import { GlEvBus } from '@/GlobalEventBus'

Vue.config.productionTip = false

/* ** VeeValidate Config Start ** */
for (let rule in rules) {
    extend(rule, {...rules[rule], message: ja.messages[rule]})
}
/* ** VeeValidate Config E N D ** */

/* 共通コンポーネント定義 START */
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
/* 共通コンポーネント定義 E N D */


/* iPhone画面変動サイズ対応 START */
const setFillHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

let mWinW = window.innerWidth
let mChkCnt = 0
const chRcompEv = function (evName) {
    let winW = window.innerWidth
    if (winW == mWinW && mChkCnt < 10) {
        mChkCnt += 1
        setTimeout(function () { chRcompEv(evName) }, 130)
    } else {
        mChkCnt = 0
        mWinW = window.innerWidth
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`)
        GlEvBus.$emit(evName)
    }
}

// 画面のサイズ変動があった時に高さを再計算する
window.addEventListener('resize', setFillHeight)
/* スマホ縦横変更 */
window.addEventListener("orientationchange", ()=>{
    if (window.orientation === 90 || window.orientation === -90 || window.orientation === 270) {
        // GlEvBus.$emit('PhoneW')
        chRcompEv('PhoneW')
    } else if (window.orientation === 0 || window.orientation === 180) {
        // GlEvBus.$emit('PhoneV')
        chRcompEv('PhoneV')
    }
})

// 画面サイズ初期化
setFillHeight()
/* iPhone画面変動サイズ対応 E N D */

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
