import axios from 'axios'
import store from '@/store'
import util$ from '@/util'
import { GlEvBus } from '@/GlobalEventBus'

export class ReqClass {
    constructor (url) {
        this.url = url.trim()
        this.reqWheres = []
        this.reqIsNullWheres = []
        this.reqNotNullWheres = []
        this.reqOrders = []
        this.applyParam = {}
        this.noticeParam = {}
        this.getParam = {}
        this.all_cnt = null
        this.offset_cnt = 0
        this.limit_cnt = null
        this.throwExceptionFlg = false
        this.rWhereKey = '_where'
        this.rIsNullWhereKey = '_is_null_where'
        this.rNotNullWhereKey = '_not_null_where'
        this.rOrderKey = '_orderby'
        this.rLimitKey = '_limit'
        this.rOffsetKey = '_offset'
        this.responseRecordsKey = '__records'
        this.responseAllCntKey = '__all_record_cnt'

        this.ax$ = axios.create({
            baseURL: store.getters.getApiUrl(),
            headers: {
                'ContentType': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            responseType: 'json',
            timeout: 120000
        })
    }

    init (url) {
        this.url = url.trim()
        this.reqWheres = []
        this.reqIsNullWheres = []
        this.reqNotNullWheres = []
        this.reqOrders = []
        this.applyParam = {}
        this.noticeParam = {}
        this.all_cnt = null
        this.offset_cnt = 0
        this.limit_cnt = null
        this.getParam = {}
    }

    getReqUrl () {
        let genUrl = store.getters.getApiUrl() + this.url
        let setWheres = []
        if (this.reqWheres.length > 0) {
            for (let idx in this.reqWheres) {
                setWheres.push(this.rWhereKey + '[]=' + JSON.stringify(this.reqWheres[idx]))
            }
            genUrl = genUrl + '?' + setWheres.join('&')
        } else {
            genUrl = genUrl + '?'
        }
        return genUrl
    }

    getAllCnt () {
        return this.all_cnt
    }

    getLimitCnt () {
        return this.limit_cnt
    }

    setErrThrow () {
        this.throwExceptionFlg = true
    }

    addWhere (setCol, setVal, setOp) {
        let setWh = {col: setCol, val: setVal}
        if (typeof setOp !== 'undefined' && setOp !== null) setWh.op = setOp
        this.reqWheres.push(setWh)
    }

    addWhereOR (setOrWheres) {
        let setWh = {'_or': setOrWheres}
        this.reqWheres.push(setWh)
    }

    addIsNullWhere (setCol) {
        this.reqIsNullWheres.push(setCol)
    }

    addNotNullWhere (setCol) {
        this.reqNotNullWheres.push(setCol)
    }

    addOrder (setCol, setDirection) {
        this.reqOrders.push({col: setCol, direction: setDirection})
    }

    setWhere (setWh) {
        this.reqWheres = setWh
    }

    setOrder (setOrder) {
        this.reqOrders = setOrder
    }

    setOffset (setOffset) {
        this.offset_cnt = setOffset
    }

    setLimit (setLimit) {
        this.limit_cnt = setLimit
    }

    setGetParam (setkey, setParam) {
        this.getParam[setkey] = setParam
    }

    get (callBack, errCallback) {
        let req = this
        let rParam = {params: {}}
        if (this.reqWheres.length > 0) rParam.params[this.rWhereKey] = this.reqWheres
        if (this.reqIsNullWheres.length > 0) rParam.params[this.rIsNullWhereKey] = this.reqIsNullWheres
        if (this.reqNotNullWheres.length > 0) rParam.params[this.rNotNullWhereKey] = this.reqNotNullWheres
        if (this.reqOrders.length > 0) rParam.params[this.rOrderKey] = this.reqOrders
        if (this.offset_cnt > 0) rParam.params[this.rOffsetKey] = this.offset_cnt

        if (this.limit_cnt !== null) {
            rParam.params[this.rLimitKey] = this.limit_cnt
        }

        if (Object.keys(req.getParam).length > 0) {
            for (let rKey in req.getParam) {
                rParam.params[rKey] = req.getParam[rKey]
            }
        }
        if (Object.keys(rParam.params).length === 0) rParam = null

        req.ax$.get(this.url, rParam)
            .then(function (res) {
                let resData = res.data
                if (typeof res.data !== 'undefined' && typeof res.data[req.responseRecordsKey] !== 'undefined' && typeof res.data[req.responseAllCntKey] !== 'undefined') {
                    req.all_cnt = res.data[req.responseAllCntKey]
                    resData = res.data[req.responseRecordsKey]
                }
                if (typeof callBack === 'function') {
                    callBack(resData)
                }
            })
            .catch(function (errRes) {
                console.log(errRes)
                req.reqErrProc(errRes, errCallback)
            })
    }

    post (postParam, callBack, errCallback) {
        let req = this

        req.ax$.post(this.url, postParam)
            .then(function (res) {
                if (typeof callBack === 'function') callBack(res.data)
            })
            .catch(function (errRes) {
                req.reqErrProc(errRes, errCallback)
            })
    }

    put (saveParam, callBack, errCallback) {
        let req = this

        req.ax$.put(this.url, saveParam)
            .then(function (res) {
                if (typeof callBack === 'function') callBack(res.data)
            })
            .catch(function (errRes) {
                req.reqErrProc(errRes, errCallback)
            })
    }

    save (saveParam, callBack, errCallback) {
        let req = this
        if (typeof saveParam.id === 'undefined' || saveParam.id === null) {
            req.post(saveParam, callBack, errCallback)
        } else {
            if (this.url.substr(1, -1) !== '/') this.url += '/'
            this.url = this.url + saveParam.id
            req.put(saveParam, callBack, errCallback)
        }
    }

    delete (delCallback, errCallback) {
        let req = this
        let rParam = {}

        req.ax$.delete(this.url, rParam)
            .then(function (res) {
                if (typeof delCallback === 'function') delCallback(res.data)
            })
            .catch(function (errRes) {
                req.reqErrProc(errRes, errCallback)
            })
    }

    reqErrProc (errRes, errCallback) {
        console.log(errRes)
        GlEvBus.$emit('HIDE_LOADING')
        if (typeof errCallback === 'function') {
            errCallback(errRes)
        } else if (typeof errRes.response !== 'undefined') {
            if (errRes.response.status >= 400 || errRes.response.status <= 600) {
                if (errRes.response.status === 500) {
                    util$.msgErrOpen('システムエラーが発生しました。')
                } else if (errRes.response.status === 503) {
                    location.href = '/maintenance.html' // メンテナンス画面へ遷移
                } else if (errRes.response.status === 400) {
                    let setErrMsg = '不正なリクエストが送信されました。'
                    if (typeof errRes.response.data.message !== 'undefined') setErrMsg += '\n' + errRes.response.data.message
                    util$.msgWarnOpen(setErrMsg)
                } else if (errRes.response.status === 401) {
                    util$.msgWarnOpen('セッションタイムアウトしました', function () { util$.tolink('login') })
                    util$.logout(true)
                } else if (errRes.response.status === 404) {
                    util$.msgWarnOpen('指定されたリソースは現在システムに存在しません', function () { util$.tolink('/') })
                } else if (errRes.response.status === 405) {
                    util$.msgWarnOpen('指定されたリソースへのアクセス件がありません', function () { util$.tolink('/') })
                } else if (errRes.response.status === 409) {
                    util$.msgInfoOpen(errRes.response.data.message)
                } else if (errRes.response.status === 422) {
                    util$.msgWarnOpen('不正なリクエストが送信されました。入力内容が不正です。')
                } else if (errRes.response.status === 429) {
                    util$.msgWarnOpen('1分間あたりのリクエスト数が多すぎる為、処理がキャンセルされました。しばらくお待ちください。')
                }
            }
        } else {
            util$.msgWarnOpen('タイムアウトエラーが発生しました。')
        }
        if (this.throwExceptionFlg) {
            throw new Error('Request Error')
        }
    }
}
