import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        apiUrl: '',
        logined: false,
        fromName: null, // 遷移元の画面名を格納する
        pageCnt: 10, // ページ当たりの表示件数
        consts: {},
        lists: {},
        input: {},
        indexCond: {},
        user: {},
        nowYmd: null
    },
    mutations: {
        setConsts: function (state, consts) {
            state.consts = consts
        },
        setLists: function (state, lists) {
            state.lists = lists
        },
        setLogined (state, loginFlg) {
            state.logined = loginFlg
        },
        setNowYmd: function (state, nowYmd) {
            state.nowYmd = nowYmd
        },
        setFromName: function (state, fromName) {
            state.fromName = (fromName !== null) ? fromName.name : ''
        },
        setInputData: function (state, input) {
            state.input = input
        },
        setIndexCond: function (state, cond) {
            state.indexCond = cond
        },
        setUser: function (state, user) {
            state.user = user
        }
    },
    getters: {
        getApiUrl: state => {
            return function () { return state.apiUrl }
        },
        getSelectList: state => (getKey) => {
            let selList = []
            for (let val in state.lists[getKey]) {
                if (!isNaN(val)) {
                    val = val * 1
                }
                selList.push({text: state.lists[getKey][val], value: val})
            }
            return selList
        },
        getSelectLists: state => {
            return function () {
                let selList = {}
                for (let key in state.lists) {
                    selList[key] = []
                    for (let val in state.lists[key]) {
                        selList[key].push({text: state.lists[key][val], value: val * 1})
                    }
                }
                return selList
            }
        },
        getConsts: state => {
            return function () { return state.consts }
        },
        getLists: state => {
            return function () { return state.lists }
        },
        getList: state => (getKey) => {
            return state.lists[getKey]
        },
        getNowYmd: state => {
            return function () { return state.nowYmd }
        },
        getFromName: state => {
            return function () { return state.fromName }
        },
        isLogin: state => {
            return function () { return state.logined }
        },
        pageCnt: state => {
            return function () { return state.pageCnt }
        },
        getInputData: state => {
            return function () { return state.input }
        },
        getIndexCond: state => (getName) => {
            if (typeof state.indexCond.name !== 'undefined' && state.indexCond.name === getName) {
                return state.indexCond
            } else {
                return null
            }
        },
        getUser: state => {
            return function () { return state.user }
        }
    },
    actions: {
    },
    modules: {
    }
})
