import Vue from 'vue'
import { GlEvBus } from '@/GlobalEventBus'
import { ReqClass } from '@/class/Request.class'
import Router from '@/router/index'
import store from '@/store'

export default {
    objCopy: function (copyObj) {
        return Array.isArray(copyObj) ? copyObj.slice() : Vue.util.extend({}, copyObj)
    },
    tolink: function (path) {
        let setPath = String(path)
        if (setPath.substring(0, 1) !== '/') setPath = '/' + setPath
        Router.push(setPath)
        scrollTo(0, 0)
    },
    startLoading: function (argObj) {
        return new Promise((resolve) => {
            GlEvBus.$emit('SHOW_LOADING')
            if (typeof argObj === 'undefined') {
                return resolve()
            } else {
                return resolve(argObj)
            }
        })
    },
    endLoading: function (argObj) {
        return new Promise((resolve) => {
            GlEvBus.$emit('HIDE_LOADING')
            if (typeof argObj === 'undefined') {
                return resolve()
            } else {
                return resolve(argObj)
            }
        })
    },
    validateChk: function (vObj, callBack) {
        vObj.$validator.validateAll().then((valiRet) => {
            if (valiRet) {
                if (typeof callBack === 'function') {
                    callBack()
                }
                return true
            } else {
                console.log('Validate Error Log')
                console.log(vObj.errors)
                GlEvBus.$emit('MsgDialogOpen', 'warning', 'フォーム入力内容に誤りがあります。\nエラーメッセージをご確認の上入力内容の修正をお願いします。')
                return false
            }
        })
    },
    notEmpty: function (chkParam) {
        return (typeof chkParam !== 'undefined' && chkParam !== null)
    },
    reload: function () {
        location.reload()
    },
    msgInfoOpen: function (msg, callBack) {
        GlEvBus.$emit('MsgDialogOpen', 'info', msg, callBack)
    },
    msgConfirmOpen: function (msg, callBack1, callback2) {
        GlEvBus.$emit('MsgDialogOpen', 'confirm', msg, callBack1, callback2)
    },
    msgDeleteOpen: function (msg, callBack1, callback2) {
        GlEvBus.$emit('MsgDialogOpen', 'delete', msg, callBack1, callback2)
    },
    msgAlertOpen: function (msg, callBack) {
        GlEvBus.$emit('MsgDialogOpen', 'alert', msg, callBack)
    },
    msgWarnOpen: function (msg, callBack) {
        GlEvBus.$emit('MsgDialogOpen', 'warning', msg, callBack)
    },
    msgErrOpen: function (msg, callBack) {
        GlEvBus.$emit('MsgDialogOpen', 'error', msg, callBack)
    },
    gen_key_list: function (records, keyCol) {
        let genList = {}
        keyCol = keyCol || 'id'

        if (records.length > 0) {
            for (let i = 0; i < records.length; i++) {
                genList[records[i][keyCol]] = records[i]
            }
        }
        return genList
    },
    searchArrColVal: function (records, searchVal, searchCol, retCol) {
        let retVal = null

        if (records.length > 0) {
            for (let i = 0; i < records.length; i++) {
                if (records[i][searchCol] === searchVal) {
                    retVal = records[i][retCol]
                }
            }
        }
        return retVal
    },
    getFileBase64: function (fileDat) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.readAsDataURL(fileDat)
            reader.onload = () => resolve(reader.result)
        })
    },
    getImgBase64: function (imgDat) {
        let cvs = document.createElement('canvas')
        cvs.width = imgDat.naturalWidth
        cvs.height = imgDat.naturalHeight
        let ctx = cvs.getContext('2d')
        ctx.drawImage(imgDat, 0, 0)
        return cvs.toDataURL('image/png')
    },
    genVuetifySelList: function (list) {
        let selList = []
        for (let val in list) {
            selList.push({text: list[val], value: val * 1})
        }
        return selList
    },
    genVuetifyObjSelList: function (list, textCol, valCol) {
        let selList = []
        for (let idx in list) {
            selList.push({text: list[idx][textCol], value: list[idx][valCol]})
        }
        return selList
    },
    canvasDownload: function (fileName, cvsObj) {
        let link = document.createElement('a')
        link.href = cvsObj.canvas.toDataURL()
        link.download = fileName
        link.click()
    },
    app: {
        getConsts: function (vueObj, callBack) {
            return new Promise((resolve) => {
                let reqObj = new ReqClass('/consts')
                reqObj.get(function (rDat) {
                    store.commit('setLogined', true)
                    store.commit('setConsts', rDat.const)
                    store.commit('setLists', rDat.list)
                    store.commit('setUser', rDat.user)
                    store.commit('setNowYmd', rDat.nowYmd)
                    if (typeof callBack === 'function') {
                        return callBack()
                    }
                    return resolve(vueObj)
                })
            })
        }
    }
}
