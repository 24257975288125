<template>
    <v-row class="pa-0 ma-0 content-body">
        <v-col cols="12" class="pa-0 ma-0">
            <v-row class="ma-0">
                <v-col cols="12" class="text-center pa-2">
                    <div class="tutorial-carousel">
                        <v-carousel hide-delimiter-background hide-delimiters height="100%" v-model="viewNo">
                            <template v-slot:prev="{ on, attrs }">
                                <button
                                    class="tutorial-prev-btn"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="viewNo !== 0"
                                    ></button>
                            </template>
                            <v-carousel-item>
                                <v-row class="ml-2 mr-2 mb-2 carousel-content-area">
                                    <v-col cols="12" class="pl-10 pr-10">
                                        <img src="/static/image/tutrial/tut1.png" width="100%" />
                                        <div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-carousel-item>
                            <v-carousel-item>
                                <v-row class="ml-2 mr-2 mb-2 carousel-content-area">
                                    <v-col cols="12" class="pl-10 pr-10">
                                        <img src="/static/image/tutrial/tut2.png" width="100%" />
                                        <div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-carousel-item>
                            <v-carousel-item>
                                <v-row class="ml-2 mr-2 mb-2 carousel-content-area">
                                    <v-col cols="12" class="pl-10 pr-10">
                                        <img src="/static/image/tutrial/tut3.png" width="100%" />
                                        <div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-carousel-item>
                            <v-carousel-item>
                                <v-row class="ml-2 mr-2 mb-2 carousel-content-area">
                                    <v-col cols="12" class="pl-10 pr-10">
                                        <img src="/static/image/tutrial/tut4.png" width="100%" />
                                        <div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-carousel-item>
                            <template v-slot:next="{ on, attrs }">
                                <button
                                    class="tutorial-next-btn"
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="viewNo !== 3"
                                    ></button>
                            </template>
                        </v-carousel>
                    </div>
                </v-col>
                <v-col cols="12" class="text-center pa-2">
                    <button class="tutorial-skip-btn" @click="util$.tolink('/select')"></button>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
// チュートリアルページ
import util$ from '@/util'

export default {
    name: 'Tutorial',
    data: function () {
        return {
            util$: util$,
            viewNo: 0
        }
    }
}
</script>
